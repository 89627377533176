import { css, keyframes, useTheme as useAppTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { promiseRetry } from '@livechat/promise-utils'

import HoverableItem from '../components/HoverableItem'
import { useTemplate } from '../hooks/useTemplate'
import { Drawer, DrawerNav } from './components/Drawer'
import { Foreground } from './components/Foreground'
import { Section } from './components/Section'
import {
	Content,
	DrawerBottom,
	Main,
	MobileContent,
	Plane,
	Title,
	WidgetContainer,
} from './components/SharedStyledComponents'
import SuccessStep from './components/SuccessStep'
import { WidgetPlane } from './components/WidgetPlane'
import BetaBadge from 'components/BetaBadge'
import { PortalOutlet, PortalProvider, createPortalContext } from 'components/Portal'
import AnimatedConfiguratorViewWrapper from 'components/SlideInWrapper'
import { UserPopoverSection } from 'components/UserPopover'
import WidgetPreview from 'components/WidgetPreview'
import { ArticleIcon, CaretLeftIcon, CodeIcon, PaletteIcon, UserAvatarIcon } from 'components/icons'
import { useAppInstances } from 'hooks/useAppInstances'
import { useApps } from 'hooks/useApps'
import { useFeature } from 'hooks/useFeature'
import { useHeader } from 'hooks/useHeader'
import { useInstagramFeed } from 'hooks/useInstagramFeed'
import { useInstallPrompt } from 'hooks/useInstallPrompt'
import { useIntegration } from 'hooks/useIntegration'
import { useOrganization } from 'hooks/useOrganization'
import { useScrollTopOnce } from 'hooks/useScrollTopOnce'
import useTemplateId from 'hooks/useTemplateId'
import { useTemplates } from 'hooks/useTemplates'
import { useTheme } from 'hooks/useTheme'
import { useTracking } from 'hooks/useTracking'
import { useUser } from 'hooks/useUser'
import { LIVECHAT_BETA_BADGE_TOOLTIP_TEXT, OPENAI_BETA_BADGE_TOOLTIP_TEXT } from 'lib/constants'
import { useAppSelector } from 'lib/store'
import { EVENT_NAMES } from 'lib/tracking'
import { Theme } from 'styles/theme'

const TemplateChooser = dynamic(() => promiseRetry(() => import('components/TemplateChooser')), {
	ssr: false,
})

const Nav = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	gap: ${({ theme }) => theme.spaces.space5};
	padding: ${({ theme }) => theme.spaces.space5};
	box-shadow: ${({ theme }) => theme.boxShadows.lg};
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
`

const navLinkStyles = (theme: Theme, isActive: boolean) => css`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: inherit;
	text-decoration: inherit;
	padding: ${theme.spaces.space2};
	border-radius: ${theme.borderRadiuses.xl};
	background-color: ${isActive ? theme.colors.light.surfaceDecorative : 'transparent'};
	position: relative;

	svg {
		z-index: ${theme.zIndexes.high};
	}
`

const ActionButons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ theme }) => (theme.isMobile ? 'space-between' : 'flex-end')};
	gap: ${({ theme }) => theme.spaces.space3};
	padding-right: ${({ theme }) => theme.spaces.space6};
`

const DrawerTop = styled(DrawerNav)`
	border: none;
	visibility: ${({ expanded }: { expanded?: boolean }) => (expanded ? 'visible' : 'hidden')};
`

const StyledDrawerBottom = styled(DrawerBottom)`
	bottom: ${({ theme }) => theme.spaces.space3};
	position: fixed;
	left: ${({ theme }) => theme.spaces.space3};
	right: ${({ theme }) => theme.spaces.space3};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
`

const bubbleScale = keyframes`
	0% {
		scale: 1
	}
	100% {
		scale: 1.4
	}
`

const OuterBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #eff0fd;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate 1s;
`

const InnerBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #d3d5fc;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate;
`

const BubbleWrapper = styled.div`
	position: absolute;
	display: grid;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	place-items: center;
	width: calc(100% + 2 * ${({ theme }) => (theme.isMobile ? theme.spaces.space6 : theme.spaces.space5)});
	height: calc(100% + 2 * ${({ theme }) => (theme.isMobile ? theme.spaces.space6 : theme.spaces.space5)});
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	z-index: ${({ theme }) => theme.zIndexes.above};
`

const NotificationDot = styled.div`
	position: absolute;
	top: -2px;
	right: -2px;
	width: 10px;
	height: 10px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ theme }) => theme.colors.light.notification};
`

const HeadingWrapper = styled.div`
	height: 50px;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space3};
	justify-content: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'space-between')};
	border-bottom: 1px solid ${({ theme }) => theme.colors.light.borderColor};
`

const BreadcrumbsWrapper = styled.nav`
	display: flex;
	gap: ${({ theme }) => theme.spaces.space3};
`

const breadcrumbStyles = (theme: Theme) => css`
	display: flex;
	gap: ${theme.spaces.space2};
	align-items: center;
	color: ${theme.colors.light.breadcrumb};
	text-decoration: none;
`

const BreadcrumbCurrent = styled.span`
	${({ theme }) => theme.typography.smallHeading};
	color: ${({ theme }) => theme.colors.light.primaryTextColor};
	white-space: nowrap;
	align-self: center;
`

const Column = styled.div<{ hasTemplateChooser?: boolean }>`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space5};
	padding-bottom: ${({ hasTemplateChooser }) => (hasTemplateChooser ? '68px' : 0)};
`

const MobileTemplateChooserWrapper = styled.div`
	padding-right: ${({ theme }) => theme.spaces.space6};
	margin-top: ${({ theme }) => theme.spaces.space3};
`

const routes = [
	{
		route: '/configurator/widget-content',
		title: 'Widget content',
		icon: <ArticleIcon />,
		children: [
			{
				pathName: 'contact',
				title: 'Contact card',
			},
			{
				pathName: 'whatsapp',
				title: 'WhatsApp',
			},
			{
				pathName: 'messenger',
				title: 'Messenger',
			},
			{
				pathName: 'faq',
				title: 'FAQ',
			},
			{
				pathName: 'product',
				title: 'Product carousel',
			},
			{
				pathName: 'visitor-counter',
				title: 'Visitor counter',
			},
			{
				pathName: 'instagram',
				title: 'Instagram',
			},
			{
				pathName: 'product-recommendations',
				title: 'Product recommendations',
			},
			{
				pathName: 'helpdesk',
				title: 'HelpDesk',
			},
			{
				pathName: 'google-reviews',
				title: 'Google reviews',
			},
			{
				pathName: 'livechat',
				title: 'LiveChat',
			},
			{
				pathName: 'chatbot',
				title: 'ChatBot',
			},
			{
				pathName: 'apps',
				title: 'Apps',
			},
			{
				pathName: 'openai-assistants',
				title: 'OpenAI Assistants',
			},
			{
				pathName: 'custom-links',
				title: 'Custom links',
			},
		],
	},
	{ route: '/configurator/customize-look', title: 'Customize look', icon: <PaletteIcon /> },
	{ route: '/configurator/code-snippet', title: 'Add to website', icon: <CodeIcon /> },
] as const

type Routes = typeof routes

export type WidgetContentRoutes = `${Routes['0']['route']}/${Routes['0']['children'][number]['pathName']}`

type WidgetContentRouteTitles = Routes['0']['children'][number]['title']

const BETA_BADGE_TOOLTIP_TEXTS: Partial<Record<WidgetContentRouteTitles, string>> = {
	LiveChat: LIVECHAT_BETA_BADGE_TOOLTIP_TEXT,
	'OpenAI Assistants': OPENAI_BETA_BADGE_TOOLTIP_TEXT,
}

const USER_ROUTE = '/configurator/user'

export const ActionButtonsPortalContext = createPortalContext()
export const HeadingControlPortalContext = createPortalContext()

function getChildrenAwareRoutes(path: string) {
	return routes.map((route) => {
		if (!('children' in route)) {
			return route
		}
		const isInNestedRoute = route.children.findIndex(({ pathName }) => path.includes(pathName)) > -1

		return isInNestedRoute ? { ...route, route: path } : route
	})
}

function HeadingBreadcrumbs({ path }: { path: string }) {
	const { withTemplateId } = useTemplateId()
	const route = routes.find(({ route }) => path.startsWith(route))
	const isNestedRoute = route && 'children' in route && route.children.some(({ pathName }) => path.includes(pathName))
	const track = useTracking()

	const bareHeading = (
		<HeadingWrapper>
			<Title>{route?.title}</Title>
		</HeadingWrapper>
	)

	if (!isNestedRoute) {
		return bareHeading
	}

	const [, , previousRoute, currentRoute] = path.split('/')

	if (!previousRoute || !currentRoute) {
		return bareHeading
	}

	const currentRouteName = route.children.find(({ pathName }) =>
		currentRoute.replace('#', '').endsWith(pathName),
	)?.title

	const betaBadgeTooltipText = currentRouteName && BETA_BADGE_TOOLTIP_TEXTS[currentRouteName]

	const handleBreadcrumbClick = () => {
		track(EVENT_NAMES.NAVIGATION_BACK_CLICKED, { sectionName: currentRoute })
	}

	return (
		<AnimatedConfiguratorViewWrapper>
			<HeadingWrapper>
				<BreadcrumbsWrapper>
					<Link
						href={withTemplateId(path.split('/').slice(0, -1).join('/'))}
						css={breadcrumbStyles}
						aria-label="navigate back to widget content"
						onClick={handleBreadcrumbClick}
					>
						<CaretLeftIcon />
					</Link>
					<BreadcrumbCurrent>{currentRouteName}</BreadcrumbCurrent>
					{betaBadgeTooltipText && <BetaBadge withIcon tooltipText={betaBadgeTooltipText} />}
				</BreadcrumbsWrapper>
				<PortalOutlet context={HeadingControlPortalContext} />
			</HeadingWrapper>
		</AnimatedConfiguratorViewWrapper>
	)
}

function ConfiguratorLayout({ children }: { children: React.ReactNode }) {
	const router = useRouter()
	const { header } = useHeader()
	const { theme } = useTheme()
	const { apps } = useApps()
	const { appInstances } = useAppInstances()
	const { feature: forms } = useFeature('forms')
	const { feature: visitorCounter } = useFeature('visitor_counter')
	const { feature: instagramFeature } = useFeature('instagram_feed')
	const { feature: productRecommendations } = useFeature('product_recommendations')
	const { feature: googleReviews } = useFeature('google_reviews')
	const { feature: faq } = useFeature('faq')
	const { feature: productCards } = useFeature('product_cards')
	const { feature: whatsapp } = useFeature('whatsapp')
	const { feature: messenger } = useFeature('messenger')
	const { feature: openaiFeature } = useFeature('openai_integration')
	const { feature: linksFeature } = useFeature('links')
	const { integration: livechatIntegration } = useIntegration('livechat')
	const { integration: openaiIntegration } = useIntegration('openai')
	const { instagramData: instagramFeed } = useInstagramFeed(
		Boolean(instagramFeature?.enabled && instagramFeature.externalProperties.connected),
		{
			isPaused: () => typeof router.query.code !== 'undefined',
		},
	)
	const user = useUser()
	const { organization } = useOrganization()
	const appTheme = useAppTheme()
	const contentRef = React.useRef<HTMLElement>(null)
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const { isVisible: isInstallPromptVisible } = useInstallPrompt()
	const { withTemplateId } = useTemplateId()
	const { templates } = useTemplates()
	const { template } = useTemplate()
	const hasMultipleTemplates = typeof templates !== 'undefined' && templates.length > 1
	const templateLanguage = template?.language ?? 'en'
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(isSuccessStepActive ? false : true)

	useScrollTopOnce(contentRef)

	if (appTheme.isMobile) {
		const shouldDisplayTemplateChooser = hasMultipleTemplates && !router.route.endsWith('user')
		return (
			<>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>
							<WidgetPreview
								organizationId={user.organizationId}
								theme={theme}
								faq={faq}
								forms={forms}
								apps={apps}
								appInstances={appInstances}
								header={header}
								visitorCounter={visitorCounter}
								productRecommendations={productRecommendations}
								googleReviews={googleReviews}
								productCards={productCards}
								livechatIntegration={livechatIntegration}
								openaiIntegration={openaiIntegration}
								openaiFeature={openaiFeature}
								instagramFeed={{ ...instagramFeature, feed: instagramFeed }}
								templateLanguage={templateLanguage}
								whatsapp={whatsapp}
								messenger={messenger}
								links={linksFeature}
							/>
						</WidgetPlane>
					</Section>
				</Foreground>
				<PortalProvider context={ActionButtonsPortalContext}>
					<PortalProvider context={HeadingControlPortalContext}>
						<Drawer
							expanded={isDrawerExpanded}
							compact={router.route.startsWith(USER_ROUTE)}
							onClick={() => setIsDrawerExpanded(true)}
							css={css({
								backgroundColor: appTheme.colors.light.primaryBackgroundColor,
								paddingTop:
									isDrawerExpanded && !router.route.includes('code-snippet') && !router.route.endsWith('user')
										? '55px'
										: '16px',
							})}
						>
							<DrawerTop expanded={isDrawerExpanded} isElevated={isSuccessStepActive}>
								<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
							</DrawerTop>
							{!router.route.endsWith('user') && <HeadingBreadcrumbs path={router.pathname} />}
							{shouldDisplayTemplateChooser && (
								<MobileTemplateChooserWrapper>
									<TemplateChooser templates={templates} />
								</MobileTemplateChooserWrapper>
							)}
							{user.role === 'administrator' && (
								<StyledDrawerBottom isElevated={isSuccessStepActive}>
									{getChildrenAwareRoutes(router.asPath).map(({ route, icon }, index) => (
										<Link
											key={route}
											href={withTemplateId(route)}
											css={navLinkStyles(appTheme, router.route.startsWith(route))}
										>
											{icon}
											{isSuccessStepActive && router.route.startsWith(route) && (
												<BubbleWrapper>
													<OuterBubble />
													<InnerBubble />
												</BubbleWrapper>
											)}
											{index === 2 && isInstallPromptVisible && <NotificationDot data-testid="notification-dot" />}
										</Link>
									))}
									<SuccessStep />
									<Link href={USER_ROUTE} css={navLinkStyles(appTheme, router.route.startsWith(USER_ROUTE))}>
										<UserAvatarIcon />
									</Link>
								</StyledDrawerBottom>
							)}
							<MobileContent
								ref={contentRef}
								compact={router.route.startsWith(USER_ROUTE)}
								shrinkHeight={shouldDisplayTemplateChooser}
							>
								{children}
							</MobileContent>
						</Drawer>
					</PortalProvider>
				</PortalProvider>
			</>
		)
	}

	return (
		<>
			<UserPopoverSection />
			<Main>
				{user.role === 'administrator' && (
					<Nav>
						{routes.map(({ title, route, icon }, index) => (
							<HoverableItem title={title} key={route}>
								<Link href={withTemplateId(route)} css={navLinkStyles(appTheme, router.route.startsWith(route))}>
									{icon}
									{isSuccessStepActive && router.route.startsWith(route) && (
										<BubbleWrapper>
											<OuterBubble />
											<InnerBubble />
										</BubbleWrapper>
									)}
									{index === 2 && isInstallPromptVisible && <NotificationDot data-testid="notification-dot" />}
								</Link>
							</HoverableItem>
						))}
					</Nav>
				)}
				<Column hasTemplateChooser={hasMultipleTemplates}>
					{hasMultipleTemplates && <TemplateChooser templates={templates} />}
					<PortalProvider context={ActionButtonsPortalContext}>
						<PortalProvider context={HeadingControlPortalContext}>
							<Plane>
								<HeadingBreadcrumbs path={router.pathname} />
								<Content ref={contentRef}>{children}</Content>
								<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
							</Plane>
						</PortalProvider>
					</PortalProvider>
				</Column>
				<WidgetContainer isSuccessStepVisible={isSuccessStepActive}>
					<SuccessStep />
					<WidgetPreview
						organizationId={user.organizationId}
						theme={theme}
						faq={faq}
						forms={forms}
						header={header}
						apps={apps}
						appInstances={appInstances}
						previewUrl={organization?.website}
						visitorCounter={visitorCounter}
						productRecommendations={productRecommendations}
						googleReviews={googleReviews}
						productCards={productCards}
						livechatIntegration={livechatIntegration}
						openaiIntegration={openaiIntegration}
						openaiFeature={openaiFeature}
						instagramFeed={{ ...instagramFeature, feed: instagramFeed }}
						templateLanguage={templateLanguage}
						whatsapp={whatsapp}
						messenger={messenger}
						links={linksFeature}
					/>
				</WidgetContainer>
			</Main>
		</>
	)
}

export default ConfiguratorLayout
