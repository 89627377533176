import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Organization } from 'types/organization'

export function useOrganization(options?: SWRConfiguration) {
	const { data, mutate, isLoading, error } = useSWR<Organization>(`${config.apiURL}/v1.0/organization`, options)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		organization: data,
		mutateOrganization: mutate,
		isOrganizationLoading: isLoading,
	}
}
