import { css } from '@emotion/react'

import { isMobile } from 'lib/helpers'

const mobile = isMobile()

export const globalStyles = ({ limitContentWidth }: { limitContentWidth: boolean }) => css`
	body {
		font-family: Inter, sans-serif;
		margin: 0;
		max-height: 100vh;
		overflow: hidden;
		background-color: #fafafa;
		-webkit-text-size-adjust: 100%;
	}

	#__next {
		max-width: ${limitContentWidth ? '1400px' : 'none'};
		margin: 0 auto;

		@media (max-width: 1550px) {
			margin: 0 ${limitContentWidth ? (mobile ? 'auto' : '72px') : ''};
		}
	}

	* {
		box-sizing: border-box;
	}
`
