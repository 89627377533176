import { css, useTheme as useAppTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import React from 'react'
import { useStore } from 'react-redux'

import { useTemplate } from '../hooks/useTemplate'
import { Drawer, DrawerNav } from './components/Drawer'
import { Foreground } from './components/Foreground'
import { Section } from './components/Section'
import { WidgetPlane } from './components/WidgetPlane'
import Button, { StyledButton } from 'components/Button'
import { PortalOutlet, PortalProvider, createPortalContext } from 'components/Portal'
import WidgetPreview from 'components/WidgetPreview'
import { TickIcon } from 'components/icons'
import { useFeature } from 'hooks/useFeature'
import { useHeader } from 'hooks/useHeader'
import { useInstallations } from 'hooks/useInstallations'
import { useOnboardingRoutes } from 'hooks/useOnboardingRoutes'
import { useOrganization } from 'hooks/useOrganization'
import { useScrollTopOnce } from 'hooks/useScrollTopOnce'
import { useTheme } from 'hooks/useTheme'

type OnboardingRouteState = 'completed' | 'current' | 'future'

const StepsStack = styled.ol`
	height: 18px;
	display: flex;
	overflow-x: auto;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: ${({ theme }) => theme.spaces.space0};
	padding: ${({ theme }) => theme.spaces.space0};
	gap: ${({ theme }) => theme.spaces.space6};

	&::-webkit-scrollbar {
		display: none;
	}

	@media (max-width: 1000px) {
		gap: ${({ theme }) => theme.spaces.space3};
	}
`

const StepIndex = styled.span`
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: ${({ theme }) => theme.fontSizes.size12};
	padding: ${({ theme }) => theme.spaces.space2};
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
`

const StepName = styled.span`
	display: inline-block;
	white-space: nowrap;
`

const Step = styled.li<{ state: OnboardingRouteState }>`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space2};
	${({ theme }) => theme.typography.captionMedium}

	@media (max-width: 1000px) {
		font-size: ${({ theme }) => theme.fontSizes.size12};
	}

	${({ theme, state }) => {
		if (state === 'completed') {
			return css`
				color: ${theme.colorPalette.green[500]};
				${StepIndex} {
					background-color: #d8f3e4;
				}
			`
		}

		if (state === 'current') {
			return css`
				color: inherit;
				${StepIndex} {
					background-color: ${theme.colors.light.surfaceDecorative};
				}
			`
		}

		if (state === 'future') {
			return css`
				opacity: 0.55;
				color: inherit;
				${StepIndex} {
					background-color: ${theme.colors.light.surfaceDecorative};
				}
			`
		}
	}}
`

const RoutesStack = styled.div`
	flex: 1;
	gap: ${({ theme }) => theme.spaces.space2};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const RouteName = styled.span`
	${({ theme }) => theme.typography.paragraphMedium}
`

const RouteProgress = styled.div`
	gap: ${({ theme }) => theme.spaces.space3};
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const RouteIndicator = styled.div<{ active: boolean }>`
	width: 6px;
	height: 6px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ active, theme }) =>
		active ? theme.colors.light.surfaceInteractive : theme.colors.light.surfaceDecorative};
`

const Content = styled.main`
	display: flex;
	gap: 32px;
	flex-direction: row;
	flex-shrink: 1;
	min-height: 0;
	height: 100%;
`

const Header = styled.header`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-content: center;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 60px;

	@media (max-width: 1000px) {
		${StyledButton} {
			padding: ${({ theme }) => `${theme.spaces.space3} ${theme.spaces.space5}`};
		}
	}
`

const NavButtons = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spaces.space5};
`

const NavButtonPlaceholder = styled.div`
	width: 50px;
`

const Container = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
`

const PreviewColumn = styled.div`
	max-height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: -1px;
`

const Footer = styled.footer`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space5};
	padding: ${({ theme }) => theme.spaces.space6} 0;
`

export const NavButtonsPortalContext = createPortalContext()

export type OnboardingLayoutChildProps = {
	hasNextRoute: boolean
	navigateToNextRoute: VoidFunction
}

function OnboardingLayout({ children }: { children: React.ReactNode }) {
	const { organization } = useOrganization()
	const { template } = useTemplate()
	const { header } = useHeader()
	const { theme } = useTheme()
	const { feature: faq } = useFeature('faq')
	const { feature: productCards } = useFeature('product_cards')
	const appTheme = useAppTheme()
	const router = useRouter()
	const store = useStore()
	const { feature: forms } = useFeature('forms')
	const { installations } = useInstallations()
	const { feature: whatsapp } = useFeature('whatsapp')
	const { feature: messenger } = useFeature('messenger')
	const { feature: googleReviews } = useFeature('google_reviews')
	const contentRef = React.useRef<HTMLElement>(null)
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(true)
	const { areRoutesLoading, routes } = useOnboardingRoutes()
	const hasInstalledWidget = installations && installations.length > 0

	const currentRouteIndex = React.useMemo(
		() => routes.findIndex(({ route }) => router.route.startsWith(route)),
		[router.route, routes],
	)

	useScrollTopOnce(contentRef)

	const hasNextRoute = currentRouteIndex < routes.length - 1
	const hasPrevRoute = currentRouteIndex > 0

	const navigateToNextRoute = () => {
		if (hasNextRoute) {
			router.push(routes[currentRouteIndex + 1].route)
		} else {
			router.replace(`/configurator/${hasInstalledWidget ? 'widget-content' : 'code-snippet'}`)
			store.dispatch({ type: 'currentModal/set', payload: 'SuccessStepModal' })
		}
	}

	const navigateToPrevRoute = () => {
		if (hasPrevRoute) {
			router.push(routes[currentRouteIndex - 1].route)
		}
	}

	const mappedChildren = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			const onboardingLayoutChildProps: OnboardingLayoutChildProps = { hasNextRoute, navigateToNextRoute }
			return React.cloneElement(child, { ...child.props, ...onboardingLayoutChildProps })
		}
	})

	if (appTheme.isMobile) {
		return (
			<>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>
							<WidgetPreview
								organizationId={organization?.id}
								theme={theme}
								faq={faq}
								forms={forms}
								header={header}
								productCards={productCards}
								templateLanguage={template?.language}
								whatsapp={whatsapp}
								messenger={messenger}
								googleReviews={googleReviews}
							/>
						</WidgetPlane>
					</Section>
				</Foreground>
				<PortalProvider context={NavButtonsPortalContext}>
					<Drawer expanded={isDrawerExpanded} onClick={() => setIsDrawerExpanded(true)}>
						<DrawerNav>
							{hasPrevRoute ? <Button onClick={navigateToPrevRoute}>Back</Button> : <NavButtonPlaceholder />}
							<RoutesStack>
								{!areRoutesLoading && <RouteName>{routes[currentRouteIndex].title}</RouteName>}
								<RouteProgress>
									{routes.map((_, index) => (
										<RouteIndicator key={index} active={index === currentRouteIndex} />
									))}
								</RouteProgress>
							</RoutesStack>
							<PortalOutlet context={NavButtonsPortalContext} Component={NavButtons} />
						</DrawerNav>
						<Section ref={contentRef}>
							<AnimatePresence>{mappedChildren}</AnimatePresence>
						</Section>
					</Drawer>
				</PortalProvider>
			</>
		)
	}

	return (
		<>
			<Container>
				<Header>
					<StepsStack>
						{!areRoutesLoading &&
							routes.map(({ route, title }, index) => {
								let routeState: OnboardingRouteState
								if (index < currentRouteIndex) {
									routeState = 'completed'
								} else if (index === currentRouteIndex) {
									routeState = 'current'
								} else {
									routeState = 'future'
								}

								return (
									<Step key={route} state={routeState}>
										<StepIndex>{routeState === 'completed' ? <TickIcon /> : index + 1}</StepIndex>
										<StepName>{title}</StepName>
									</Step>
								)
							})}
					</StepsStack>
				</Header>
				<PortalProvider context={NavButtonsPortalContext}>
					<Content>
						<Section ref={contentRef}>
							<AnimatePresence>{mappedChildren}</AnimatePresence>
						</Section>
						<PreviewColumn>
							<Section>
								<WidgetPlane>
									<WidgetPreview
										organizationId={organization?.id}
										theme={theme}
										faq={faq}
										forms={forms}
										header={header}
										productCards={productCards}
										previewUrl={organization?.website}
										templateLanguage={template?.language}
										whatsapp={whatsapp}
										messenger={messenger}
										googleReviews={googleReviews}
									/>
								</WidgetPlane>
							</Section>
							<Footer>
								{currentRouteIndex > 0 && <Button onClick={navigateToPrevRoute}>Back</Button>}
								<PortalOutlet context={NavButtonsPortalContext} Component={NavButtons} />
							</Footer>
						</PreviewColumn>
					</Content>
				</PortalProvider>
			</Container>
		</>
	)
}

export default OnboardingLayout
