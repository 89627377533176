import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'
import React from 'react'

import { Alignment } from 'types/theme'

const WIDGET_OUTER_WIDTH = 392

const Inner = styled(m.div)<{
	isOnboarding: boolean
	isSuccessStepActive: boolean
	selectedVisibilityTarget: 'desktop' | 'mobile'
	alignment?: Alignment | null
}>`
	position: relative;
	flex: 1;
	height: ${({ alignment, selectedVisibilityTarget }) =>
		alignment
			? `calc(100% - ${selectedVisibilityTarget === 'desktop' ? alignment.offsetY : alignment.mobileOffsetY}px)`
			: '100%'};
	display: flex;
	justify-content: center;
	transform-origin: ${({ alignment, selectedVisibilityTarget }) =>
			selectedVisibilityTarget === 'desktop' ? alignment?.screenPosition : alignment?.mobileScreenPosition}
		bottom !important;

	${({ alignment, isSuccessStepActive, selectedVisibilityTarget }) =>
		alignment &&
		!isSuccessStepActive &&
		css`
			${selectedVisibilityTarget === 'desktop'
				? alignment.screenPosition
				: alignment.mobileScreenPosition}: ${selectedVisibilityTarget === 'desktop'
				? alignment.offsetX
				: alignment.mobileOffsetX}px;
			bottom: ${selectedVisibilityTarget === 'desktop' ? alignment.offsetY : alignment.mobileOffsetY}px;
		`}

	${({ isSuccessStepActive, theme }) =>
		isSuccessStepActive &&
		theme.isMobile &&
		css`
			transform: scale(0.95);
			filter: blur(8px);
			transform: translate3d(0, 0, 0);
		`};

	${({ theme }) =>
		theme.isMobile
			? css`
					max-width: 100%;
					max-height: calc(100% - 80px);
			  `
			: css`
					min-width: ${WIDGET_OUTER_WIDTH}px;
					max-width: ${WIDGET_OUTER_WIDTH}px;
					max-height: 682px;
			  `};

	${({ theme, isOnboarding }) =>
		!theme.isMobile &&
		isOnboarding &&
		css`
			@media (max-height: 855px) {
				scale: 0.9;
				height: 110%;
			}
			@media (max-height: 810px) {
				scale: 0.8;
				height: 125%;
			}
			@media (max-height: 740px) {
				scale: 0.7;
				height: 145%;
			}
			@media (max-height: 660px) {
				scale: 0.6;
				height: 170%;
			}
		`}
`

const Outer = styled.div<{ screenPosition?: Alignment['screenPosition'] }>`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'flex-end')};

	${({ screenPosition }) =>
		screenPosition &&
		css`
			justify-content: ${screenPosition === 'left' ? 'flex-start' : 'flex-end'};
		`};
`

type Props = {
	isOnboarding: boolean
	isSuccessStepActive?: boolean
	selectedVisibilityTarget?: 'desktop' | 'mobile'
	alignment?: Alignment | null | undefined
	children: React.ReactNode
}

const WidgetOuter = ({
	children,
	alignment,
	isSuccessStepActive,
	selectedVisibilityTarget = 'desktop',
	isOnboarding,
}: Props) => {
	const theme = useTheme()

	if (!theme.isMobile && !alignment) {
		return null
	}

	if (isSuccessStepActive) {
		return (
			<Inner
				isOnboarding={isOnboarding}
				isSuccessStepActive
				alignment={alignment}
				selectedVisibilityTarget={selectedVisibilityTarget}
				transition={{ duration: 0.5, ease: theme.transitions.easings.motion.speedySwift }}
				data-testid="widget-preview"
			>
				{children}
			</Inner>
		)
	}

	return (
		<Outer
			screenPosition={
				selectedVisibilityTarget === 'desktop' ? alignment?.screenPosition : alignment?.mobileScreenPosition
			}
		>
			<Inner
				layout
				isOnboarding={isOnboarding}
				isSuccessStepActive={false}
				selectedVisibilityTarget={selectedVisibilityTarget}
				alignment={alignment}
				transition={{ duration: 0.5, ease: theme.transitions.easings.motion.speedySwift }}
				data-testid="widget-preview"
			>
				{children}
			</Inner>
		</Outer>
	)
}

export default WidgetOuter
