import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { m } from 'framer-motion'

import { DrawerNav } from 'layouts/components/Drawer'
import { Section } from 'layouts/components/Section'

export const Title = styled.h1`
	${({ theme }) => theme.typography.heading};
	font-size: 24px;
	margin: ${({ theme }) => `${theme.spaces.space5} 0 ${theme.isMobile ? theme.spaces.space7 : theme.spaces.space5}`};
`

export const Main = styled.main`
	position: absolute;
	top: ${({ theme }) => theme.spaces.space7};
	bottom: ${({ theme }) => theme.spaces.space7};
	left: ${({ theme }) => theme.spaces.space6};
	right: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space6};
`

export const Plane = styled.div`
	position: relative;
	width: 420px;
	height: 100%;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	gap: ${({ theme }) => theme.spaces.space6};
	padding: ${({ theme }) => theme.spaces.space6};
	padding-right: 0;
	box-shadow: ${({ theme }) => theme.boxShadows.lg};
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
`

export const Content = styled.section`
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: ${({ theme }) => theme.spaces.space1};
	position: relative;
`

type MobileContentProps = {
	compact?: boolean
	shrinkHeight?: boolean
}

const getHeightOffset = ({ compact, shrinkHeight }: MobileContentProps): number => {
	if (compact) {
		return 50
	}

	if (shrinkHeight) {
		return 170
	}

	return 100
}

export const MobileContent = styled(Section)<MobileContentProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100% - ${getHeightOffset}px);
	border: none;
	margin: 0;
	overflow-y: auto;
	padding-top: ${({ theme }) => theme.spaces.space3};
	position: relative;
`

export const DrawerBottom = styled(DrawerNav)`
	justify-content: space-between;
	align-items: center;
	top: unset;
	border: none;
	height: 66px;
	padding: 0 30px;
	bottom: 0;
`

export const WidgetContainer = styled.div<{ isSuccessStepVisible?: boolean }>`
	height: 100%;
	width: 100%;
	${({ theme, isSuccessStepVisible }) =>
		isSuccessStepVisible &&
		css`
			position: fixed;
			display: grid;
			grid-auto-flow: column;
			z-index: ${theme.zIndexes.base};
			gap: ${theme.spaces.space7};
			place-items: center;
			place-content: center;
		`}
`

export const DarkBackground = styled(m.div)`
	width: 100vw;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: #0009;
	z-index: ${({ theme }) => theme.zIndexes.base};
`
