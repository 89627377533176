import styled from '@emotion/styled'
import { m } from 'framer-motion'

import { DrawerNav } from './Drawer'

export const Title = styled.h1`
	${({ theme }) => theme.typography.heading};
	font-size: 24px;
	margin: ${({ theme }) => `${theme.spaces.space5} 0 ${theme.isMobile && theme.spaces.space7}`};
`

export const Main = styled.main`
	position: absolute;
	top: 24px;
	bottom: 24px;
	left: 16px;
	right: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space6};
`

export const Plane = styled.div`
	width: 420px;
	height: 100%;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	gap: ${({ theme }) => theme.spaces.space6};
	padding: ${({ theme }) => theme.spaces.space6};
	box-shadow: ${({ theme }) => theme.boxShadows.lg};
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
`
export const Content = styled.section`
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: ${({ theme }) => theme.spaces.space1};
`

export const DrawerBottom = styled(DrawerNav)`
	justify-content: space-between;
	align-items: center;
	top: unset;
	border: none;
	height: 66px;
	padding: 0 30px;
	bottom: 0;
`

export const WidgetContainer = styled.div`
	height: 100%;
	width: 100%;
`

export const Tooltip = styled(m.div)`
	${({ theme }) => theme.typography.smallcaps}
	position: absolute;
	white-space: pre;
	font-weight: 400;
	padding: ${({ theme }) => `${theme.spaces.space2} ${theme.spaces.space3}`};
	z-index: ${({ theme }) => theme.zIndexes.above};
	border-radius: ${({ theme }) => theme.borderRadiuses.def};
	background: ${({ theme }) => theme.colors.light.hintSurface};
	color: ${({ theme }) => theme.colors.dark.primaryTextColor};
`
