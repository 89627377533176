import styled from '@emotion/styled'
import React from 'react'

const INITIALS_CIRCLE_COLORS = [
	'#8608ff',
	'#ca0005',
	'#328dff',
	'#00893c',
	'#9146ff',
	'#ff5101',
	'#0066ff',
	'#a372ff',
	'#f3c600',
	'#00a449',
]

type Props = {
	colorIndex: number
	textForInitials: string
} & React.HTMLAttributes<HTMLDivElement>

const InitialsWrapper = styled.div<{ background: string }>`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	color: ${({ theme }) => theme.colors.light.surface};
	background-color: ${({ background }) => background};
	${({ theme }) => theme.typography.captionMedium};
`

function NameBadge({ colorIndex, textForInitials, ...rest }: Props) {
	const initials = React.useMemo(() => {
		const parts = textForInitials.split(' ')
		const firstName = parts[0]
		const lastName = parts.length > 1 ? parts[parts.length - 1] : null

		if (firstName && lastName) {
			return `${firstName[0]}${lastName[0]}`.toUpperCase()
		}

		if (firstName) {
			return firstName[0].toUpperCase()
		}

		return ''
	}, [textForInitials])

	const circleColor = INITIALS_CIRCLE_COLORS[colorIndex % INITIALS_CIRCLE_COLORS.length]

	return (
		<InitialsWrapper background={circleColor} {...rest}>
			<span aria-hidden>{initials}</span>
		</InitialsWrapper>
	)
}

export default NameBadge
