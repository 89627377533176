import * as React from 'react'
import styled from '@emotion/styled'
import { useHover } from '@react-aria/interactions'

import { Tooltip } from 'layouts/components/SharedConfiguratorComponents'

type Props = {
	children: React.ReactNode
	title: string | React.ReactNode
	position?: Pick<React.CSSProperties, 'top' | 'right' | 'bottom' | 'left'>
}

const Wrapper = styled.div`
	display: flex;
`

const StyledTooltip = styled(Tooltip)<{ position: Props['position'] }>`
	max-width: 180px;
	white-space: normal;
	margin-top: 6px;
	transition: 0.3s ${({ theme }) => theme.transitions.easings.motion.smooth};
	${({ position }) => position};
`

function HoverableItem({ children, title, position = { left: '50px' }, ...restProps }: Props) {
	const { hoverProps, isHovered } = useHover({})
	const { hoverProps: tooltipHoverProps, isHovered: isTooltipHovered } = useHover({})
	const [isDebouncedHovered, setIsDebouncedHovered] = React.useState(false)

	React.useEffect(() => {
		const timeout = setTimeout(() => setIsDebouncedHovered(isHovered), 100)
		return () => clearTimeout(timeout)
	}, [isHovered])

	return (
		<Wrapper {...hoverProps} {...restProps}>
			{(isDebouncedHovered || isTooltipHovered) && (
				<StyledTooltip
					position={position}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					{...(tooltipHoverProps as Omit<React.ComponentProps<typeof StyledTooltip>, 'position'>)}
				>
					{title}
				</StyledTooltip>
			)}
			{children}
		</Wrapper>
	)
}

export default HoverableItem
